import React, { FC } from "react"
import styled from "styled-components"

interface InfoBoxProps {
  title: string
  description: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  max-width: 320px;
  padding: 10px;
  height: 230px;
  > h4 {
    color: ${({ theme }) => theme.colors.neutralText};
    font-weight: 700;
    font-size: 24px;
  }
  > p {
    color: ${({ theme }) => theme.colors.neutralText};
    font-weight: 400;
    font-size: 18px;
    text-align: center;
  }
`

const Line = styled.div`
  height: 3px;
  width: 70%;
  background-color: ${({ theme }) => theme.colors.primary};
  margin: 10px 0;
`

export const InfoBox: FC<InfoBoxProps> = ({ title, description }) => {
  return (
    <Container>
      <h4>{title}</h4>
      <Line />
      <p>{description}</p>
    </Container>
  )
}

export default InfoBox
